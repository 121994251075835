.shortcut {
    margin-right: 10px;
    cursor: pointer;
}

.aggregate {
    font-weight: 700;
}

.elynx-cell-text {
    cursor: pointer
}