/* 
/////////////////////////////////////////////
// SPR - Dense Grid - Add
.ag-root {
  --font-size: 12px;
  --max-header-height: calc(var(--font-size) * 4px);
  --cell-padding: 0 4px !important;
}
/////////////////////////////////////////////
*/

.ag-theme-alpine .ag-row-odd {
  background-color: rgba(245, 245, 245, 1);
}

.ag-theme-alpine .ag-row-group {
    background-color: rgba(41, 57, 85, 0.03);
    font-weight: 500;
    /* 
    /////////////////////////////////////////////
    // SPR - Dense Grid - Add
    font-size: var(--font-size); 
    /////////////////////////////////////////////
    */
}

.ag-theme-alpine-dark .ag-row-group {
    background-color: #121212;
    font-weight: 500;
    /* 
    /////////////////////////////////////////////
    // SPR - Dense Grid - Add
    font-size: var(--font-size); 
    /////////////////////////////////////////////
    */
}

.ag-theme-alpine,
.ag-theme-alpine-dark {
  font-family: "Roboto";
}

a {
  color: inherit;
  text-decoration: inherit;
}

.ag-theme-alpine .ag-row.comm-error {
  background-color: #fafad2
}

.ag-theme-alpine-dark .ag-row.comm-error {
  background-color: #424242
}

.ag-theme-alpine .ag-cell .numeric-input,
.ag-theme-alpine-dark .ag-cell .numeric-input {
    /* width: 100%;
    height: 100%; */
    position: absolute;
    top:0;
    bottom:0;
    left:0;
    right:0;
    cursor: pointer;
    border: none;
    color: inherit;
    background-color: inherit;
}
 
.ag-theme-alpine .ag-cell .numeric-input:focus,
.ag-theme-alpine-dark .ag-cell .numeric-input:focus {
  outline: none
}

.ag-header-group-cell-label {
    flex-direction: column;
    align-self: center!important;
}

/* 
/////////////////////////////////////////////
// SPR - Dense Grid - Add
.ag-theme-alpine .ag-header-cell,
.ag-theme-alpine-dark .ag-header-cell {
  padding: var(--cell-padding);
  font-size: var(--font-size);
  overflow: hidden;
  white-space: normal !important;
}

.ag-theme-alpine .header-cell-action-bar,
.ag-theme-alpine-dark .header-cell-action-bar {
  padding: var(--cell-padding);
}

.ag-theme-alpine .ag-cell,
.ag-theme-alpine-dark .ag-cell {
  padding: var(--cell-padding);
  font-size: var(--font-size);
  display: flex !important;
  align-items: center;
}

.ag-theme-alpine .ag-header-cell-comp-wrapper,
.ag-theme-alpine-dark .ag-header-cell-comp-wrapper {
  padding-bottom: 24px;
}
/////////////////////////////////////////////  
*/

.ag-header-cell-text {
  white-space: normal !important;
  word-break:normal !important;
}

.ag-theme-alpine .elynx-gauge-sheet-row-first {
  background-color: #dcdcdc!important;
  pointer-events: none!important;
}

.ag-theme-alpine-dark .elynx-gauge-sheet-row-first {
  background-color: rgba(0, 0, 0, 0.87)!important;
  pointer-events: none!important;
}


.eLynx-gauge-sheet-row-tank-event {
  border-bottom-color: darkred !important;
  border-bottom-style: solid !important;
  border-bottom-width: 3px !important;
}

.ag-header-row-column-group .ag-react-container {
  display: 'flex';
  flex: 1 1 auto
}


/*default from ag grid is 150px, so if there are grids with autoheight and one row there is a lot of space*/
.ag-theme-alpine .ag-layout-auto-height .ag-center-cols-clipper, .ag-theme-alpine .ag-layout-auto-height .ag-center-cols-container, .ag-theme-alpine .ag-layout-print .ag-center-cols-clipper, .ag-theme-alpine .ag-layout-print .ag-center-cols-container,
.ag-theme-alpine-dark .ag-layout-auto-height .ag-center-cols-clipper, .ag-theme-alpine .ag-layout-auto-height .ag-center-cols-container, .ag-theme-alpine .ag-layout-print .ag-center-cols-clipper, .ag-theme-alpine .ag-layout-print .ag-center-cols-container { 
  min-height: 42px!important;
}

.ag-theme-alpine-dark .elynx-group-manual-entry .ag-header-group-cell-label {
  align-items: flex-start;
}

.ag-theme-alpine .elynx-group-manual-entry .ag-header-group-cell-label {
  align-items: flex-start;
}