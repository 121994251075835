/* chrome custom scrollbar */
/* width */
::-webkit-scrollbar {
  width: 14px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #a9a9a9; 
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}
/* firefox custom scrollbar*/
/* .elynx-scroller {
  scrollbar-color: #f8f8f8 transparent;
  scrollbar-width: thin;
} */



/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

/* .ag-theme-alpine,
.ag-theme-alpine-dark,
.ag-theme-balham,
.ag-theme-balham-dark {
  font-family: "Roboto"
}

a {
  color: inherit;
  text-decoration: inherit;
}

.ag-theme-alpine .ag-row.comm-error {
  background-color: #fafad2
}

.ag-theme-alpine-dark .ag-row.comm-error {
  background-color: #424242
}

.ag-theme-alpine .ag-cell .numeric-input,
.ag-theme-alpine-dark .ag-cell .numeric-input {
    width: 100%;
    height: 100%;
    border: none;
    color: inherit;
    background-color: inherit;
}
 
.ag-theme-alpine .ag-cell .numeric-input:focus,
.ag-theme-alpine-dark .ag-cell .numeric-input:focus {
  outline: none
} */
