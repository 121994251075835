@charset "UTF-8";

/* @font-face {
  font-family: "scadalynx-web";
  src:url("scadalynx-web.eot");
  src:url("scadalynx-web.eot?#iefix") format("embedded-opentype"),
    url("scadalynx-web.woff") format("woff"),
    url("scadalynx-web.ttf") format("truetype"),
    url("scadalynx-web.svg#scadalynx-web") format("svg");
  font-weight: normal;
  font-style: normal;

} */
@font-face {
  font-family: scadalynx-web;
  src:url(../fonts/scadalynx-web.eot);
  src:url(../fonts/scadalynx-web.eot?#iefix) format("embedded-opentype"),
    url(../fonts/scadalynx-web.woff) format("woff"),
    url(../fonts/scadalynx-web.ttf) format("truetype"),
    url(../fonts/scadalynx-web.svg#scadalynx-web) format("svg");
  font-weight: normal;
  font-style: normal;

}

[data-icon]:before {
  font-family: scadalynx-web !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  /* speak: none; */
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="sw-"]:before,
[class*=" sw-"]:before {
  font-family: scadalynx-web !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  /* speak: none; */
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.sw-chart-report:before {
  content: "\69";
}
.sw-chemical-composition:before {
  content: "\6a";
}
.sw-data-analytics-circular-graphic:before {
  content: "\6b";
}
.sw-decline-graph:before {
  content: "\6c";
}
.sw-grid:before {
  content: "\6d";
}
.sw-grid-chart:before {
  content: "\6e";
}
.sw-industrial-pipe:before {
  content: "\6f";
}
.sw-money-graph:before {
  content: "\70";
}
.sw-oil-drum:before {
  content: "\71";
}
.sw-oil-pumpjack:before {
  content: "\72";
}
.sw-oil-tower:before {
  content: "\73";
}
.sw-sinewave:before {
  content: "\74";
}
.sw-tanks:before {
  content: "\75";
}
.sw-tanks2:before {
  content: "\76";
}
.sw-two-grids:before {
  content: "\77";
}
.sw-line:before {
  content: "\61";
}
.sw-report:before {
  content: "\61";
}
.sw-line-chart:before {
  content: "\63";
}
.sw-ruler:before {
  content: "\64";
}
.sw-tank:before {
  content: "\65";
}
.sw-close-button:before {
  content: "\62";
}
.sw-cross-out:before {
  content: "\66";
}
.sw-circular-chart-2:before {
  content: "\67";
}
